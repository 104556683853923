import Vue from "vue";
import Vuex from "vuex";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store/index";
import { Watch } from "vue-property-decorator";

Vue.use(VueRouter);

// @ts-ignore
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/homePage/index.vue"),
    children: [],
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/homePage/index.vue"),
    children: [],
  },
  {
    path: "/system/admin",
    name: "system_admin",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/users/index.vue")
  },
  {
    path: "/system/menu",
    name: "menu",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/menu/index.vue")
  },
  {
    path: "/system/permission",
    name: "permission",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/permission/index.vue")
  },
  {
    path: "/system/role",
    name: "role",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/role/index.vue")
  },
  {
    path: "/system/tag",
    name: "tag",
    component: () => import(/* webpackChunkName: "about" */ "@/views/system/tag/index.vue")
  },
   //命签管理
   {
    path: "/system/yaoqian",
    name: "yaoqian",
    component: () =>
        import(/* webpackChunkName: "about" */ "@/views/system/yaoqian/index.vue"),
  },


  {
    path: "/device",
    name: "device_management",
    component: () => import(/* webpackChunkName: "about" */ "@/views/device/index.vue")
  },
  {
    path: "/media",
    name: "media_management",
    component: () => import(/* webpackChunkName: "about" */ "@/views/media/index.vue")
  },

  {
    path: "/version",
    name: "version",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/version/index.vue"),
  },

  //商铺管理
  {
    path: "/store",
    name: "store",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/store/index.vue"),
    children: [],
  },
  //商户类型
  {
    path: "/store/type",
    name: "storeType",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/storeType/index.vue"),
    children: [],
  },
  
  

  //商品类别
  {
    path: "/goods/types",
    name: "goods_types",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/goods/types/index.vue"),
    children: [],
  },

  {
    path: "/goods/tags",
    name: "goods_tags",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/goods/tags/index.vue"),
    children: [],
  },

  //商品管理
  {
    path: "/goods",
    name: "goods",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/goods/index.vue"),
    children: [],
  },
  

  //活动管理
  {
    path: "/banner",
    name: "banner",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/banner/index.vue"),
    children: [],
  },




  {
    path: "/user/users",
    name: "userList",
    component: () => import(/* webpackChunkName: "about" */ "@/views/user/users/index.vue")
  },
  {
    path: "/opinion",
    name: "opinion",
    component: () => import(/* webpackChunkName: "about" */ "@/views/opinion/index.vue")
  },
  {
    path: "/question",
    name: "question",
    component: () => import(/* webpackChunkName: "about" */ "@/views/question/index.vue")
  },
  {
    path: "/order",
    name: "order",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/order/index.vue"),
  },
  {
    path: "/mine",
    name: "mine",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/mine/index.vue"),
  },

 
];

const router = new VueRouter({
  mode:'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
