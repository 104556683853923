const url = {
    dev: {
        // publicUrl: 'http://127.0.0.1:9511/admin',
        // manageUrl: 'http://127.0.0.1:9511/admin'
      //  publicUrl: 'https://bagman.zd-aichong.com/api/admin',
      publicUrl:'http://114.55.1.115:9511/admin',//'http://111.0.130.145:19511/admin',
      //测试 'http://111.0.130.145:19511/admin',
      //生产  http://114.55.1.115:9511/admin
        //manageUrl: 'https://bagman.zd-aichong.com/api/admin'
        manageUrl: 'http://114.55.1.115:9511/admin'//'http://111.0.130.145:19511/admin'
    },
    pro: {
        publicUrl: 'http://114.55.1.115:9511/admin',//'http://111.0.130.145:19511/admin',
        manageUrl: 'http://114.55.1.115:9511/admin',//'http://111.0.130.145:19511/admin'
       // publicUrl: 'https://bagman.zd-aichong.com/api/admin',
        //manageUrl: 'https://bagman.zd-aichong.com/api/admin'
    }
}

export const get = () => {
    if(process.env.NODE_ENV === 'production'){
        return url['pro']
    }else{
        return url['dev']
    }
}
